import { isZeroed } from '@ltvco/refresh-lib/utils';
import { Text, Box, alpha } from '@ltvco/refresh-lib/theme';
import { ReportRouteProps } from 'components/reports/types';
import { getFraudSearchSubtitleText } from './utils';
import {
  SearchWrapper,
  SearchFormsContainer,
  DetailsContainer,
  ReportContainer,
} from './components';
import { useState } from 'react';
import {
  DebugMenu,
  FraudSearchContainer,
  FraudUrlAdditionalDetails,
  FraudUrlOverview,
  FraudUrlThreatDetection,
  ReportLoading,
  ReportNullState,
  SearchFormProps,
  UrlFraudReport,
  useReport,
} from '@ltvco/refresh-lib/v1';

interface UrlFraudReportPageProps extends ReportRouteProps {
  /** Callback to handle successful search form submission */
  onSearchSuccess: SearchFormProps['onSuccess'];
  /** Callback to handle search form submission errors */
  onSearchError: SearchFormProps['onError'];
}

export function UrlFraudReportPage({
  onSearchSuccess,
  onSearchError,
  permalink,
}: UrlFraudReportPageProps) {
  // hooks
  const queryResult = useReport(permalink);
  const zeroed = isZeroed(queryResult);

  // state
  const [tabState, setTabState] = useState<string>('url');

  // constants
  const urlSearchParams = new URLSearchParams(window.location.search);

  if (queryResult.isLoading || queryResult.isError) {
    return <ReportLoading menuItems={2} />;
  }
  if (zeroed) {
    return <ReportNullState />;
  }

  const report = new UrlFraudReport(queryResult?.data);

  if (!report) return <ReportLoading menuItems={2} />;
  if (report?.data?.isEmpty) {
    return <ReportNullState />;
  }

  let url = report.data.url || urlSearchParams.get('url') || '';
  if (url.toLowerCase().startsWith('www.')) {
    url = url.replace('www.', 'https://');
  }
  if (!url.toLowerCase().startsWith('http')) {
    url = `https://${url}`;
  }
  const { hostname } = new URL(url);
  document.title = `${hostname} - BeenVerified`;

  const {
    domain,
    isSuspicious,
    isUnsafe,
    isAdult,
    isHostedContent,
    isPhishing,
    isSpam,
    countryCode,
    pageTitle,
    pageSize,
    category,
    isRedirected,
    isMalware,
    statusCode,
    isShortLinkRedirect,
    technologies,
    contentType,
    server,
    riskScore,
    display,
    extended,
  } = report.data;

  const {
    domain: domainName,
    isRiskyTld,
    age,
    isParked,
    trust,
    isDmarcRecord,
    isSpfRecord,
    domainRank,
    isDnsValid,
    mxRecords,
    nsRecords,
  } = domain;

  return (
    <>
      <SearchWrapper>
        <SearchFormsContainer>
          <Text data-cy="fraud-check-form-title" variant="h2" color="success">
            FraudScan
          </Text>
          <Text
            mb={(theme) => ({ xs: theme.spacing(1), md: theme.spacing(3) })}
            data-cy="fraud-check-form-subtitle"
            variant="body2"
          >
            {getFraudSearchSubtitleText(tabState)}
          </Text>
          <FraudSearchContainer
            initialTab={'url'}
            onSearchSuccess={onSearchSuccess}
            onSearchError={onSearchError}
            onSearchChange={setTabState}
          />
        </SearchFormsContainer>
      </SearchWrapper>
      <ReportContainer
        sx={{ width: 'fit-content' }}
        container
        direction={'row'}
        columns={12}
      >
        <DetailsContainer mt={3} mb={3} width="100%">
          <Text
            mb={1.5}
            data-cy="fraud-check-form-title"
            variant="h3"
            color="success"
          >
            Malicious URL Scanning
          </Text>
          <Box
            padding={1}
            mb={4}
            sx={(theme) => ({
              backgroundColor: alpha(theme.palette.success.dark, 0.04),
            })}
          >
            <Text variant="h5" component="p" fontWeight={700} m={0}>
              Results for: {url}
            </Text>
          </Box>
        </DetailsContainer>
        <FraudUrlOverview
          domain={domainName}
          isSuspicious={isSuspicious}
          isUnsafe={isUnsafe}
          ipCountryCode={countryCode}
          pageTitle={pageTitle}
          siteCategory={category}
          isRedirected={isRedirected}
          finalUrl={display || extended || url}
        />
        <Box my={3} width="100%">
          <FraudUrlThreatDetection
            riskScore={riskScore}
            domainTrust={trust}
            isMalware={isMalware}
            isPhishing={isPhishing}
            isParked={isParked}
            isSpam={isSpam}
            statusCode={statusCode}
            isShortLinkRedirect={isShortLinkRedirect}
            isRiskyTld={isRiskyTld}
            isAdult={isAdult}
          />
        </Box>
        <FraudUrlAdditionalDetails
          isHostedContent={isHostedContent}
          technologies={technologies}
          isDmarcRecord={isDmarcRecord}
          isSpfRecord={isSpfRecord}
          year={age?.parsed?.year}
          month={age?.parsed?.month}
          contentType={contentType}
          pageSize={pageSize}
          server={server}
          domainRank={domainRank}
          isDnsValid={isDnsValid}
          mxRecords={mxRecords}
          nsRecords={nsRecords}
        />
      </ReportContainer>
      <DebugMenu menuItems={report.data.rawData.debug_menu} />
    </>
  );
}
